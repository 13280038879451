import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {
  ChangeCurrencyAction,
  ChangeRateCardForJobAction,
  CreateInvoiceAction,
  FindAllCurrencyAction, FindCommitmentInvoicesForOrgAction,
  FindByInvoiceTypeAndApprovalTypeAction,
  FindByInvoiceTypeAndOverdueTypeAction, FindCommitmentContractOrganizations,
  FindInvoiceByIdAction,
  GetAllTaxByCountryAction,
  GetCustomersIdsAction,
  GetInvoiceAbleJobsAction, GetInvoiceLoadsLimit,
  GetLoadsByJobFileAndCompletedAction,
  GetLoadsForJobFileAction,
  GetNoOfCompletedLoadsForCreatedToAction,
  GetOrgDetailsAction,
  GetPreSignedFile,
  GetRateCardsByIds, GetSupplierIdsAction,
  LoadRateCardsAction,
  RemoveAllLoadsAction,
  RemoveLoadAction,
  RemoveSelectedLoadsAction,
  ResetInvoiceState,
  SelectAllLoadsAction,
  SelectLoadAction,
  UpdateInvoiceAction,
  UploadPdfAndGetId,
  SelectAllLoadsActionByOrg,
  UpdateReadyToInvoiceAction
} from './invoice.actions';
import {InvoiceStateModel} from '../../models/state/invoice-state.model';
import {Subject} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {SetNotificationAction} from '@core/store/notification/notification.actions';
import {AlertDialogType} from '@shared/models';
import {GraphqlClientService} from '@core/services/graphql-client.service';
import {InvoiceService} from '../../services/invoice.service';
import {OrgDetails} from '@shared/models/org-details.model';
import {EntityMap} from '@shared/models/types';
import {PageInvoiceAbleJobs} from '../../models/pagiable-invoice-able-jobs.model';
import {PageReadyToInvoice} from '../../models/pagiable-ready-to-invoice.model';
import {InvoiceLoad} from '../../models/invoice-load.model';
import {ReadyToInvoice} from '../../models/ready-to-invoice';
import {produce} from 'immer';
import {PageInvoices} from '../../models/pagiable-invoice.model';
import {Invoice} from '@shared/models/invoice/invoice.model';
import {Tax} from '../../models/tax.model';
import {RateCard} from '@shared/models/rate-card.model';
import {LoadsCount} from '../../models/loads-count.model';
import {SetSpinner} from '@core/store/shared.actions';
import {Router} from '@angular/router';

import {
  changeRateCardForTrip,
  countCompletedLoadsByCreatedTo,
  createInvoice,
  fidByCountry,
  findAllCompletedLoadsByJob,
  findAllCurrency,
  findByInvoiceTypeAndOverdueType,
  findCustomers,
  findInvoiceAbleJobs,
  findInvoiceById,
  findInvoicesByInvoiceTypeAndApprovalType,
  findCommitmentInvoicesForOrganization,
  findLoadsByJobRefId,
  findLoadsByJobRefIdAndIsCompleted,
  findSuppliers,
  removeAllLoads,
  removeLoad,
  updateInvoice,
  uploadPdfAndGetId,
  findAllCompletedLoadsByOrg,
  updateReadyToInvoiceCostsDiscounts
} from '../../queries/invoice.query';
import {Currency} from '@shared/models/currency.model';
import {Cost} from '../../models/cost.model';
import {InvoiceType} from '../../models/enums/invoice-type.enum';

export const getNewInvoice = (): InvoiceStateModel => ({
  orgDetails: {},
  customerIds: [],
  supplierIds: [],
  commitmentContractOrgIds: [],
  jobsForCreatedTo: {},
  completedLoadsForJob: {},
  pendingLoadsForJob: {},
  loadsForJob: {},
  selectedLoads: {},
  selectedInvoice: null,
  invoices: {},
  taxes: [],
  rateCards: [],
  noOfCompletedLoadsForCreatedTo: {},
  pdfFileId: null,
  currencies: [],
  supplierInvoices: {}
}) as InvoiceStateModel;

@State<InvoiceStateModel>({
  name: 'invoice',
  defaults: getNewInvoice()
})
@Injectable()
export class InvoiceState {
  endpoint = 'invoice-endpoint';

  constructor(
    private router: Router,
    public store: Store,
    private gqlService: GraphqlClientService,
    private invoiceService: InvoiceService,
  ) {
  }

  @Selector()
  static getCustomerIds(state: InvoiceStateModel): string[] {
    return [...state.customerIds].sort();
  }

  @Selector()
  static getSupplierIds(state: InvoiceStateModel): string[] {
    return [...state.supplierIds].sort();
  }

  @Selector()
  static getOrgDetailsAsArray(state: InvoiceStateModel): OrgDetails[] {
    return Object.keys(state.orgDetails).map((id: string) => {
      return state.orgDetails[id];
    });
  }

  @Selector()
  static getOrgDetails(state: InvoiceStateModel): EntityMap<string, OrgDetails> {
    return state.orgDetails;
  }

  @Selector()
  static getJobsForCreatedTo(state: InvoiceStateModel): EntityMap<string, PageInvoiceAbleJobs> {
    return state.jobsForCreatedTo;
  }

  @Selector()
  static getCompletedLoadsForJob(state: InvoiceStateModel): EntityMap<string, PageReadyToInvoice> {
    return state.completedLoadsForJob;
  }

  @Selector()
  static getPendingLoadsForJob(state: InvoiceStateModel): EntityMap<string, PageReadyToInvoice> {
    return state.pendingLoadsForJob;
  }

  @Selector()
  static getLoadsForJob(state: InvoiceStateModel): EntityMap<string, PageReadyToInvoice> {
    return state.loadsForJob;
  }

  @Selector()
  static getSelectedLoadsAsArray(state: InvoiceStateModel): InvoiceLoad[] {
    return Object.keys(state.selectedLoads).map((id: string) => {
      return state.selectedLoads[id];
    });
  }

  @Selector()
  static getInvoices(state: InvoiceStateModel): EntityMap<string, PageInvoices> {
    return state.invoices;
  }

  @Selector()
  static getSupplierInvoices(state: InvoiceStateModel): EntityMap<string, PageInvoices> {
    return state.supplierInvoices;
  }

  @Selector()
  static getSelectedInvoice(state: InvoiceStateModel): Invoice {
    return state.selectedInvoice;
  }

  @Selector()
  static getAllTaxes(state: InvoiceStateModel): Tax[] {
    return state.taxes;
  }

  @Selector()
  static getRateCards(state: InvoiceStateModel): RateCard[] {
    return state.rateCards;
  }

  @Selector()
  static getNoOfCompletedLoadsForCreatedTo(state: InvoiceStateModel): EntityMap<string, number> {
    return state.noOfCompletedLoadsForCreatedTo;
  }

  @Selector()
  static getPdfFileId(state: InvoiceStateModel): string {
    return state.pdfFileId;
  }

  @Selector()
  static getCurrencies(state: InvoiceStateModel): Currency[] {
    return state.currencies;
  }

  @Selector()
  static getRateCardsMap(state: InvoiceStateModel): EntityMap<string, RateCard> {
    return state.rateCardsMap;
  }

  @Selector()
  static getInvoiceLoadsLimit(state: InvoiceStateModel): number {
    return state.invoiceLoadsLimit;
  }

  @Selector()
  static findCommitmentContractOrgIds(state: InvoiceStateModel): string[] {
    return state.commitmentContractOrgIds;
  }

  @Action(GetInvoiceAbleJobsAction)
  getInvoiceAbleJobs({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                     {orgId, createdTo, pageNo, pageSize, searchKey, invoiceType, fromDate, toDate}: GetInvoiceAbleJobsAction) {
    const variables = {orgId, createdTo, pageNo, pageSize, searchKey, invoiceType, fromDate, toDate};

    return this.gqlService
      .queryDataFromGQL<any>(variables, findInvoiceAbleJobs, 'findInvoiceAbleJobs', this.endpoint)
      .pipe(
        tap(jobs => {
          const obj = {
            content: jobs.data,
            page: {
              totalElements: jobs.totalElements,
              pageSize: jobs.pageSize,
              pageNumber: jobs.pageNo
            }
          } as PageInvoiceAbleJobs;
          patchState({
            jobsForCreatedTo: {...getState().jobsForCreatedTo, [createdTo]: obj}
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to load jobs', AlertDialogType.ERROR)))
      );
  }

  @Action(GetNoOfCompletedLoadsForCreatedToAction)
  getNoOfCompletedLoadsForCreatedToAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                                          {orgId, createdToIds, isCompleted, invoiceType, fromDate, toDate}: GetNoOfCompletedLoadsForCreatedToAction) {
    const variables = {orgId, createdToIds, isCompleted, invoiceType, fromDate, toDate};

    return this.gqlService
      .queryDataFromGQL<LoadsCount[]>(variables, countCompletedLoadsByCreatedTo, 'countCompletedLoadsByCreatedTo', this.endpoint)
      .pipe(
        tap((loadsCount: LoadsCount[]) => {
          const noOfCompletedLoadsForCreatedTo = {...getState().noOfCompletedLoadsForCreatedTo};
          loadsCount.forEach(loadCount => {
            noOfCompletedLoadsForCreatedTo[loadCount.createdTo] = loadCount.count;
          });
          patchState({
            noOfCompletedLoadsForCreatedTo
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to load jobs', AlertDialogType.ERROR)))
      );
  }

  @Action(CreateInvoiceAction)
  createInvoiceAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {createInvoiceInput}: CreateInvoiceAction) {
    const variables = {createInvoiceInput};
    return this.gqlService.mutateDataFromGQL<any>(variables, createInvoice, 'createInvoice', this.endpoint)
      .pipe(
        tap(invoice => {
          if (invoice.type === InvoiceType.PAYABLE) {
            this.router.navigate(['/invoice-management/ps-progress-line'], {queryParams: {id: invoice._id}});
          } else {
            this.router.navigate(['/invoice-management/progress-line'], {queryParams: {id: invoice._id}});
          }
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to create invoice', AlertDialogType.ERROR)))
      );
  }

  @Action(UpdateInvoiceAction)
  updateInvoiceAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {invoice}: UpdateInvoiceAction) {
    const variables = {updateInvoiceInput: invoice};
    return this.gqlService.mutateDataFromGQL<any>(variables, updateInvoice, 'updateInvoice', this.endpoint)
      .pipe(
        tap(updatedInvoice => {
          patchState({
            selectedInvoice: updatedInvoice
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to update invoice', AlertDialogType.ERROR)))
      );
  }

  @Action(ChangeCurrencyAction)
  changeCurrencyAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {invoice}: ChangeCurrencyAction) {
    const variables = {updateInvoiceInput: invoice};
    return this.gqlService.mutateDataFromGQL<any>(variables, updateInvoice, 'updateInvoice', this.endpoint)
      .pipe(
        tap(updatedInvoice => {
          patchState({
            selectedInvoice: updatedInvoice
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to update invoice', AlertDialogType.ERROR)))
      );
  }

  @Action(RemoveLoadAction)
  removeLoad({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {id, invoiceType, invoiceId}: RemoveLoadAction) {
    const variables = {id, invoiceId, invoiceType};
    return this.gqlService.mutateDataFromGQL<any>(variables, removeLoad, 'removeLoad', this.endpoint)
      .pipe(
        tap(updatedInvoice => {
          patchState({
            selectedInvoice: updatedInvoice
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to update invoice', AlertDialogType.ERROR)))
      );
  }

  @Action(RemoveAllLoadsAction)
  removeAllLoads({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {invoiceId, invoiceType}: RemoveAllLoadsAction) {
    const variables = {invoiceId, invoiceType};
    return this.gqlService.mutateDataFromGQL<any>(variables, removeAllLoads, 'removeAllLoads', this.endpoint)
      .pipe(
        tap(updatedInvoice => {
          patchState({
            selectedInvoice: updatedInvoice
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to update invoice', AlertDialogType.ERROR)))
      );
  }

  @Action(GetCustomersIdsAction)
  getCustomerIdsAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {orgId, fromDate, toDate}: GetCustomersIdsAction) {
    const completionSubject: Subject<void> = new Subject();
    const variables = {orgId, fromDate, toDate};

    this.gqlService.queryDataFromGQL<[]>(variables, findCustomers, 'findCustomers', this.endpoint)
      .pipe(
        tap(customers => patchState({
          customerIds: customers
        })),
        catchError(() => dispatch(new SetNotificationAction('Unable to load customer ids', AlertDialogType.ERROR)))
      ).subscribe(_ => {
      completionSubject.next();
      completionSubject.complete();
    });
    return completionSubject;
  }

  @Action(GetSupplierIdsAction)
  getSupplierIdsAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {orgId, fromDate, toDate}: GetSupplierIdsAction) {
    const completionSubject: Subject<void> = new Subject();
    const variables = {orgId, fromDate, toDate};

    this.gqlService.queryDataFromGQL<[]>(variables, findSuppliers, 'findSuppliers', this.endpoint)
      .pipe(
        tap(suppliers => patchState({
          supplierIds: suppliers
        })),
        catchError(() => dispatch(new SetNotificationAction('Unable to load supplier ids', AlertDialogType.ERROR)))
      ).subscribe(_ => {
      completionSubject.next();
      completionSubject.complete();
    });
    return completionSubject;
  }

  @Action(GetOrgDetailsAction)
  getOrgDetailsAction({patchState, getState}: StateContext<InvoiceStateModel>, {orgIds}: GetOrgDetailsAction) {
    const completionSubject = new Subject<void>();
    this.invoiceService.getOrgDetails(orgIds).pipe(
      tap(orgDetailsList => {
        const orgDetailsMap = {...getState().orgDetails};
        orgDetailsList.forEach(orgDetails => {
          orgDetailsMap[orgDetails.orgId] = orgDetails;
        });
        patchState({
          orgDetails: orgDetailsMap
        });
      })
    ).subscribe(_ => {
      completionSubject.next();
      completionSubject.complete();
    });
    return completionSubject;
  }

  @Action(GetLoadsByJobFileAndCompletedAction)
  getLoadsByJobFileAndCompletedAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                                      {orgId, createdTo, jobRefId, isCompleted, pageNo, pageSize, invoiceType}: GetLoadsByJobFileAndCompletedAction) {
    const completionSubject: Subject<void> = new Subject();
    const variables = {orgId, createdTo, jobRefId, isCompleted, pageNo, pageSize, invoiceType};

    this.gqlService
      .queryDataFromGQL<any>(variables, findLoadsByJobRefIdAndIsCompleted, 'findLoadsByJobRefIdAndIsCompleted', this.endpoint)
      .pipe(
        tap(loads => {
          const obj = {
            content: loads.data,
            page: {
              totalElements: loads.totalElements,
              pageSize: loads.pageSize,
              pageNumber: loads.pageNo
            }
          } as PageReadyToInvoice;

          if (isCompleted) {
            patchState({
              completedLoadsForJob: {...getState().completedLoadsForJob, [jobRefId]: obj}
            });
          } else {
            patchState({
              pendingLoadsForJob: {...getState().pendingLoadsForJob, [jobRefId]: obj}
            });
          }

        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to fetch jobs loads', AlertDialogType.ERROR)))
      ).subscribe(_ => {
      completionSubject.next();
      completionSubject.complete();
    });
    return completionSubject;
  }

  @Action(GetLoadsForJobFileAction)
  getLoadsForJobFileAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                           {orgId, jobRefId, pageNo, pageSize}: GetLoadsForJobFileAction) {
    const completionSubject: Subject<void> = new Subject();
    const variables = {orgId, jobRefId, pageNo, pageSize};

    this.gqlService.queryDataFromGQL<any>(variables, findLoadsByJobRefId, 'findLoadsByJobRefId', this.endpoint)
      .pipe(
        tap(loads => {
          const obj = {
            content: loads.data,
            page: {
              totalElements: loads.totalElements,
              pageSize: loads.pageSize,
              pageNumber: loads.pageNo
            }
          } as PageReadyToInvoice;
          patchState({
            loadsForJob: {...getState().loadsForJob, [jobRefId]: obj}
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to fetch jobs loads', AlertDialogType.ERROR)))
      ).subscribe(_ => {
      completionSubject.next();
      completionSubject.complete();
    });
    return completionSubject;
  }

  @Action(SelectAllLoadsActionByOrg)
  SelectAllJobLoadAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                         {orgId, createdTo, isCompleted, checked,  searchKey, invoiceType, fromDate, toDate}: SelectAllLoadsActionByOrg) {
    if (checked) {
      const completionSubject: Subject<void> = new Subject();
      const variables = {orgId, createdTo, isCompleted, searchKey, invoiceType, fromDate, toDate};

      this.gqlService.queryDataFromGQL<any>(variables, findAllCompletedLoadsByOrg, 'findAllCompletedLoadsByOrg', this.endpoint)
        .pipe(
          tap((readyToInvoices: ReadyToInvoice[]) => {
            const selectedLoads = {...getState().selectedLoads};
            readyToInvoices.forEach(readyToInvoice => {
              const billableExpenses = readyToInvoice.expenses.map(exp => {
                return {
                  id: exp.id,
                  value: exp.amount,
                  description: exp.costDescription,
                  feeType: exp.feeType

                } as Cost;
              });

              const commissions = readyToInvoice?.commissions.map(exp => {
                return {
                  id: exp.id,
                  value: exp.amount,
                  description: exp.costDescription,
                  feeType: exp.feeType
                } as Cost;
              });

              const addedAdditionalCost =  readyToInvoice.addedAdditionalCost.map(exp => {
                return {
                  id: exp.id,
                  value: exp.amount,
                  description: exp.costDescription,
                  paymentType: exp?.paymentType
                } as Cost;
              });

              const addedOtherCost = readyToInvoice.addedOtherCost.map(exp => {
                return {
                  id: exp.id,
                  value: exp.amount,
                  description: exp.costDescription,
                  paymentType: exp?.paymentType
                } as Cost;
              });
              let addedDiscount;
              if (readyToInvoice.addedDiscount){
                addedDiscount = {
                  id: readyToInvoice?.addedDiscount.id,
                  value: readyToInvoice?.addedDiscount.amount,
                  description: readyToInvoice?.addedDiscount.costDescription,
                  paymentType: readyToInvoice.addedDiscount?.paymentType
              };

            }
              const load = {
                _id: readyToInvoice._id,
                jobRefId: readyToInvoice.jobRefId,
                loadId: readyToInvoice.loadId,
                loadNumber: readyToInvoice.loadNumber,
                costs: billableExpenses,
                loadCost: readyToInvoice.loadCost,
                jpmId: readyToInvoice.jpmId,
                total: 0,
                createdTo: readyToInvoice.createdTo,
                containerType: readyToInvoice.containerType,
                jobTitle: readyToInvoice.jobTitle,
                defaultOrgCurrencyCode: readyToInvoice.rateCardCalculationRequest.rateSheet.defaultOrgCurrencyCode,
                currencyCode: readyToInvoice.rateCardCalculationRequest.rateSheet.currencyCode,
                exchangeRate: readyToInvoice.rateCardCalculationRequest.rateSheet.exchangeRate,
                paymentTerm: readyToInvoice.paymentTerm,
                rateCard: readyToInvoice.rateCard,
                locations: readyToInvoice.locations,
                distance: readyToInvoice.rateCardCalculationRequest.trips.reduce((total, trip) => total + trip.distance, 0),
                operationId: readyToInvoice.operationId,
                contractRefNo: readyToInvoice.contractRefNo,
                addedAdditionalCost,
                addedDiscount,
                addedOtherCost,
                vehicleCategories: readyToInvoice.vehicleCategories,
                commissions: commissions
              } as InvoiceLoad;
              if (Object.keys(selectedLoads).length > 0) {
                if (
                  selectedLoads[Object.keys(selectedLoads)[0]].currencyCode === readyToInvoice.rateCardCalculationRequest.rateSheet.currencyCode
                ) {
                  selectedLoads[load._id] = load;
                }
              } else {
                if (
                  readyToInvoices[0].rateCardCalculationRequest.rateSheet.currencyCode === readyToInvoice.rateCardCalculationRequest.rateSheet.currencyCode
                ) {
                  selectedLoads[load._id] = load;
                }
              }
            });
            patchState({
              selectedLoads
            });
          }),
          catchError(() => dispatch(new SetNotificationAction('Unable to fetch jobs loads', AlertDialogType.ERROR)))
        ).subscribe(_ => {
        completionSubject.next();
        completionSubject.complete();
      });
      return completionSubject;
    } else {

          const jobLoads = {...getState().selectedLoads};

          Object.keys(jobLoads).forEach(key => {
          patchState({
            selectedLoads: produce(getState().selectedLoads, (draft) => {
              delete draft[key];
            }),
          });
      });
    }
  }

  @Action(SelectAllLoadsAction)
  selectAllLoadsAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                       {orgId, jobRefId, isCompleted, checked}: SelectAllLoadsAction) {

    if (checked) {
      const completionSubject: Subject<void> = new Subject();
      const variables = {orgId, jobRefId, isCompleted};

      this.gqlService.queryDataFromGQL<any>(variables, findAllCompletedLoadsByJob, 'findAllCompletedLoadsByJob', this.endpoint)
        .pipe(
          tap((readyToInvoices: ReadyToInvoice[]) => {
            const selectedLoads = {...getState().selectedLoads};
            readyToInvoices.forEach(readyToInvoice => {
              const billableExpenses = readyToInvoice.expenses.map(exp => {
                return {
                  id: exp.id,
                  value: exp.amount,
                  description: exp.costDescription,
                  feeType: exp.feeType
                } as Cost;
              });

              const commissions = readyToInvoice?.commissions.map(exp => {
                return {
                  id: exp.id,
                  value: exp.amount,
                  description: exp.costDescription,
                  feeType: exp.feeType
                } as Cost;
              });

              const addedAdditionalCost =  readyToInvoice?.addedAdditionalCost?.map(exp => {
                return {
                  id: exp.id,
                  value: exp.amount,
                  description: exp.costDescription,
                  paymentType: exp?.paymentType
                } as Cost;
              });

              const addedOtherCost = readyToInvoice?.addedOtherCost?.map(exp => {
                return {
                  id: exp.id,
                  value: exp.amount,
                  description: exp.costDescription,
                  paymentType: exp?.paymentType
                } as Cost;
              });
              let addedDiscount;
              if (readyToInvoice.addedDiscount){
                  addedDiscount = {
                    id: readyToInvoice?.addedDiscount.id,
                    value: readyToInvoice?.addedDiscount.amount,
                    description: readyToInvoice?.addedDiscount.costDescription,
                    paymentType: readyToInvoice?.addedDiscount?.paymentType
                };

              }
              const load = {
                _id: readyToInvoice._id,
                jobRefId: readyToInvoice.jobRefId,
                loadId: readyToInvoice.loadId,
                loadNumber: readyToInvoice.loadNumber,
                costs: billableExpenses,
                loadCost: readyToInvoice.loadCost,
                jpmId: readyToInvoice.jpmId,
                total: 0,
                createdTo: readyToInvoice.createdTo,
                containerType: readyToInvoice.containerType,
                jobTitle: readyToInvoice.jobTitle,
                defaultOrgCurrencyCode: readyToInvoice.rateCardCalculationRequest.rateSheet.defaultOrgCurrencyCode,
                currencyCode: readyToInvoice.rateCardCalculationRequest.rateSheet.currencyCode,
                exchangeRate: readyToInvoice.rateCardCalculationRequest.rateSheet.exchangeRate,
                paymentTerm: readyToInvoice.paymentTerm,
                rateCard: readyToInvoice.rateCard,
                locations: readyToInvoice.locations,
                distance: readyToInvoice.rateCardCalculationRequest.trips.reduce((total, trip) => total + trip.distance, 0),
                operationId: readyToInvoice.operationId,
                contractRefNo: readyToInvoice.contractRefNo,
                addedAdditionalCost,
                addedDiscount,
                addedOtherCost,
                vehicleCategories: readyToInvoice.vehicleCategories,
                commissions: commissions
              } as InvoiceLoad;

              if (Object.keys(selectedLoads).length > 0) {
                if (
                  selectedLoads[Object.keys(selectedLoads)[0]].currencyCode === readyToInvoice.rateCardCalculationRequest.rateSheet.currencyCode
                ) {
                  selectedLoads[load._id] = load;
                }
              } else {
                if (
                  readyToInvoices[0].rateCardCalculationRequest.rateSheet.currencyCode === readyToInvoice.rateCardCalculationRequest.rateSheet.currencyCode
                ) {
                  selectedLoads[load._id] = load;
                }
              }
            });
            patchState({
              selectedLoads
            });
          }),
          catchError(() => dispatch(new SetNotificationAction('Unable to fetch jobs loads', AlertDialogType.ERROR)))
        ).subscribe(_ => {
        completionSubject.next();
        completionSubject.complete();
      });
      return completionSubject;
    } else {
      const jobLoads = {...getState().selectedLoads};
      Object.keys(jobLoads).forEach(key => {
        if (jobLoads[key].jobRefId === jobRefId) {
          patchState({
            selectedLoads: produce(getState().selectedLoads, (draft) => {
              delete draft[key];
            }),
          });
        }
      });
    }
  }

  @Action(SelectLoadAction)
  selectLoadAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {jpmId, jobRefId, checked}: SelectLoadAction) {
    if (checked) {
      const completedLoads = [...getState().completedLoadsForJob[jobRefId].content];
      const readyToInvoice = completedLoads.find(rti => rti.jpmId === jpmId);
      let billableExpenses = [];
      let addedAdditionalCost = [];
      let addedOtherCost = [];
      let addedDiscount;
      billableExpenses = readyToInvoice.expenses.map(exp => {
        return {
          id: exp.id,
          value: exp.amount,
          description: exp.costDescription,
          feeType: exp.feeType
        } as Cost;
      });

      const commissions = readyToInvoice?.commissions.map(exp => {
        return {
          id: exp.id,
          value: exp.amount,
          description: exp.costDescription,
          feeType: exp.feeType
        } as Cost;
      });

      addedAdditionalCost =  readyToInvoice?.addedAdditionalCost.map(exp => {
        return {
          id: exp.id,
          value: exp.amount,
          description: exp.costDescription,
          paymentType: exp?.paymentType
        } as Cost;
      });

      addedOtherCost = readyToInvoice?.addedOtherCost.map(exp => {
        return {
          id: exp.id,
          value: exp.amount,
          description: exp.costDescription,
          paymentType: exp?.paymentType
        } as Cost;
      });

      if (readyToInvoice.addedDiscount){
        addedDiscount = {
          id: readyToInvoice?.addedDiscount.id,
          value: readyToInvoice?.addedDiscount.amount,
          description: readyToInvoice?.addedDiscount.costDescription,
          paymentType: readyToInvoice?.addedDiscount?.paymentType
      };

    }
      const load = {
        _id: readyToInvoice._id,
        loadId: readyToInvoice.loadId,
        loadNumber: readyToInvoice.loadNumber,
        loadCost: readyToInvoice.loadCost,
        costs: billableExpenses,
        jpmId: readyToInvoice.jpmId,
        jobRefId: readyToInvoice.jobRefId,
        total: 0,
        createdTo: readyToInvoice.createdTo,
        containerType: readyToInvoice.containerType,
        jobTitle: readyToInvoice.jobTitle,
        defaultOrgCurrencyCode: readyToInvoice.rateCardCalculationRequest.rateSheet.defaultOrgCurrencyCode,
        currencyCode: readyToInvoice.rateCardCalculationRequest.rateSheet.currencyCode,
        exchangeRate: readyToInvoice.rateCardCalculationRequest.rateSheet.exchangeRate,
        paymentTerm: readyToInvoice.paymentTerm,
        rateCard: readyToInvoice.rateCard,
        locations: readyToInvoice.locations,
        distance: readyToInvoice.rateCardCalculationRequest.trips.reduce((total, trip) => total + trip.distance, 0),
        operationId: readyToInvoice.operationId,
        contractRefNo: readyToInvoice.contractRefNo,
        addedAdditionalCost,
        addedDiscount,
        addedOtherCost,
        vehicleCategories: readyToInvoice.vehicleCategories,
        commissions: commissions
      } as InvoiceLoad;
      patchState({
        selectedLoads: {...getState().selectedLoads, [load._id]: load}
      });
    } else {
      patchState({
        selectedLoads: produce(getState().selectedLoads, (draft) => {
          delete draft[jpmId];
        }),
      });
    }
  }

  @Action(FindByInvoiceTypeAndApprovalTypeAction)
  findByITypeAndAType({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                      {
                        orgId,
                        tabType,
                        approvalType,
                        pageNo,
                        pageSize,
                        invoiceType,
                        searchKey,
                        fromDate,
                        toDate
                      }: FindByInvoiceTypeAndApprovalTypeAction) {
    const completionSubject: Subject<void> = new Subject();
    const variables = {orgId, tabType, approvalType, pageNo, pageSize, invoiceType, searchKey, fromDate, toDate};
    return this.gqlService
      .queryDataFromGQL<any>(variables, findInvoicesByInvoiceTypeAndApprovalType, 'findInvoicesByInvoiceTypeAndApprovalType', this.endpoint)
      .pipe(
        tap(page => {
          const obj = {
            content: page.data,
            page: {
              totalElements: page.totalElements,
              pageSize: page.pageSize,
              pageNumber: page.pageNo
            }
          } as PageInvoices;
          patchState({
            invoices: {...getState().invoices, [tabType.toString()]: obj}
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to fetch invoices', AlertDialogType.ERROR)))
      ).subscribe(_ => {
        completionSubject.next();
        completionSubject.complete();
      });
    return completionSubject;
  }

  @Action(FindByInvoiceTypeAndOverdueTypeAction)
  findByITypeAndOType({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                      {orgId, tabType, overdueState, pageNo, pageSize, invoiceType, fromDate, toDate, searchKey}: FindByInvoiceTypeAndOverdueTypeAction) {
    const completionSubject: Subject<void> = new Subject();
    const variables = {orgId, tabType, overdueState, pageNo, pageSize, invoiceType, fromDate, toDate, searchKey};
    return this.gqlService
      .queryDataFromGQL<any>(variables, findByInvoiceTypeAndOverdueType, 'findByInvoiceTypeAndOverdueType', this.endpoint)
      .pipe(
        tap(page => {
          const obj = {
            content: page.data,
            page: {
              totalElements: page.totalElements,
              pageSize: page.pageSize,
              pageNumber: page.pageNo
            }
          } as PageInvoices;
          patchState({
            invoices: {...getState().invoices, [tabType.toString()]: obj}
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to fetch invoices', AlertDialogType.ERROR)))
      ).subscribe(_ => {
        completionSubject.next();
        completionSubject.complete();
      });
    return completionSubject;
  }

  @Action(FindInvoiceByIdAction)
  findByInvoiceById({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                    {orgId, id}: FindInvoiceByIdAction) {
    const variables = {orgId, id};
    return this.gqlService.queryDataFromGQL<any>(variables, findInvoiceById, 'findInvoiceById', this.endpoint)
      .pipe(
        tap(invoice => {
          patchState({
            selectedInvoice: invoice
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to fetch invoices', AlertDialogType.ERROR)))
      );
  }

  @Action(RemoveSelectedLoadsAction)
  removeSelectedLoads({patchState}: StateContext<InvoiceStateModel>, {}: RemoveSelectedLoadsAction) {
    patchState({
      selectedLoads: {}
    });
  }

  @Action(GetAllTaxByCountryAction)
  getAllTaxByCountryAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                           {countryCode}: GetAllTaxByCountryAction) {
    const variables = {countryCode};
    return this.gqlService.queryDataFromGQL<any>(variables, fidByCountry, 'findByCountry', this.endpoint)
      .pipe(
        tap(tax => {
          patchState({
            taxes: tax
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to fetch taxes', AlertDialogType.ERROR)))
      );
  }

  @Action(LoadRateCardsAction)
  loadRateCards({dispatch, getState, patchState}: StateContext<InvoiceStateModel>, {orgId, contractType, organizationId, searchKey}: LoadRateCardsAction) {
    return this.invoiceService.getRateCardsByFilters(orgId, contractType, organizationId, '', searchKey)
      .pipe(
        map(rateCards => patchState({rateCards})),
        catchError(() => dispatch(new SetNotificationAction('Error loading Rate Cards', AlertDialogType.ERROR)))
      );
  }

  @Action(ChangeRateCardForJobAction)
  changeRateCardForTripAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                              {jpmId, createdTo,  rateCardId}: ChangeRateCardForJobAction) {
    const variables = {jpmId, createdTo, rateCardId};
    return this.gqlService.mutateDataFromGQL<any>(variables, changeRateCardForTrip, 'changeRateCardForTrip', this.endpoint)
      .pipe(
        tap(message => {
          dispatch(new SetNotificationAction(message, AlertDialogType.SUCCESS));
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to update invoice', AlertDialogType.ERROR)))
      );
  }

  @Action(UploadPdfAndGetId)
  downloadInvoicePdfAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {orgId, invoiceId}: UploadPdfAndGetId) {
    const variables = {orgId, invoiceId};
    return this.gqlService.mutateDataFromGQL<any>(variables, uploadPdfAndGetId, 'uploadPdfAndGetId', this.endpoint)
      .pipe(
        tap(fileId => {
          dispatch(new GetPreSignedFile(fileId));
          patchState({
            pdfFileId: fileId
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable get pdf id', AlertDialogType.ERROR)))
      );
  }

  @Action(GetPreSignedFile)
  downloadJobSummary({dispatch, patchState, getState}: StateContext<InvoiceStateModel>, {fileId}: GetPreSignedFile) {
    return this.invoiceService.getPdfPreSIgnedUrl(fileId)
      .pipe(
        mergeMap(res => {
          return this.invoiceService.getPDFFileData(res.url);
        }),
        tap(response => {
          const a = document.createElement('a');
          a.href = URL.createObjectURL(response);
          a.download = `Invoice.pdf`;
          document.body.appendChild(a);
          a.click();
          dispatch(new SetSpinner(false));
        }),
        catchError(() => dispatch(new SetNotificationAction('Error Downloading Summary PDF', AlertDialogType.ERROR)))
      );
  }

  @Action(FindAllCurrencyAction)
  findAllCurrencyAction({patchState, dispatch}: StateContext<InvoiceStateModel>, {}: FindAllCurrencyAction) {
    const variables = {};
    return this.gqlService.queryDataFromGQL<any>(variables, findAllCurrency, 'findAllCurrency', this.endpoint)
      .pipe(
        tap(currencies => {
          patchState({
            currencies
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to find currencies', AlertDialogType.ERROR)))
      );
  }

  @Action(GetRateCardsByIds)
  getRateCardsByIds({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {rateCardIds}: GetRateCardsByIds) {
    return this.invoiceService.getRateCardsByIds(rateCardIds)
      .pipe(
        tap(rateCards => {
          const rateCardsMap = {...getState().rateCardsMap};
          rateCards.forEach(rateCard => {
            rateCardsMap[rateCard.rateCardId] = rateCard;
          });
          patchState({
            rateCardsMap
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to get rate cards', AlertDialogType.ERROR)))
      );
  }

  @Action(ResetInvoiceState)
  resetInvoiceState({patchState}: StateContext<InvoiceStateModel>) {
    return patchState(getNewInvoice());
  }

  @Action(GetInvoiceLoadsLimit)
  getInvoiceLoadsLimit({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {orgId}: GetInvoiceLoadsLimit) {
    return this.invoiceService.getOrganizationInvoiceLoadsLimit(orgId)
      .pipe(
        tap(invoiceLoadsLimit => {
          patchState({
            invoiceLoadsLimit
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to get invoice loads limit', AlertDialogType.ERROR)))
      );
  }

  @Action(FindCommitmentContractOrganizations)
  findCommitmentSuppliers({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                          {orgId, contractType, fromDate, toDate}: FindCommitmentContractOrganizations) {
    return this.invoiceService.findCommitmentContractOrganizations(orgId, contractType, fromDate, toDate)
      .pipe(
        tap(commitmentContractOrgIds => {
          patchState({
            commitmentContractOrgIds
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to get commitment suppliers', AlertDialogType.ERROR)))
      );
  }

  @Action(FindCommitmentInvoicesForOrgAction)
  findByInvoiceForSupplierAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>,
                                 {orgId, organizationId, invoiceType, pageNo,
                                   pageSize, searchKey, fromDate, toDate}: FindCommitmentInvoicesForOrgAction) {
    const completionSubject: Subject<void> = new Subject();
    const variables = {orgId, organizationId, invoiceType, pageNo, pageSize, searchKey, fromDate, toDate};
    return this.gqlService
      .queryDataFromGQL<any>(variables, findCommitmentInvoicesForOrganization, 'findCommitmentInvoicesForOrganization', this.endpoint)
      .pipe(
        tap(page => {
          const obj = {
            content: page.data,
            page: {
              totalElements: page.totalElements,
              pageSize: page.pageSize,
              pageNumber: page.pageNo
            }
          } as PageInvoices;
          patchState({
            supplierInvoices: {...getState().invoices, [organizationId]: obj}
          });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to fetch organization invoices', AlertDialogType.ERROR)))
      ).subscribe(_ => {
        completionSubject.next();
        completionSubject.complete();
      });
    return completionSubject;
  }

  @Action(UpdateReadyToInvoiceAction)
  updateReadyToInvoiceAction({patchState, dispatch, getState}: StateContext<InvoiceStateModel>, {readyToInvoice}: UpdateReadyToInvoiceAction) {
    const {_id, addedAdditionalCost , addedOtherCost, expenses, addedDiscount, commissions} = readyToInvoice;
    const variables = {UpdateReadyToInvoiceDiscountCostInput: {_id, addedAdditionalCost, addedDiscount, addedOtherCost, expenses, commissions}};
    return this.gqlService.mutateDataFromGQL<any>(variables, updateReadyToInvoiceCostsDiscounts, 'updateReadyToInvoiceCostsDiscounts', this.endpoint)
      .pipe(
        tap(updatedInvoice => {
         const data = getState().completedLoadsForJob[readyToInvoice.jobRefId];
         const updatedData = data.content.map(item => {
          if (item._id === updatedInvoice._id) {
            return {
              ...item,
              ...updatedInvoice
            };
          }
          return item;
        });
         patchState({
              completedLoadsForJob: {...getState().completedLoadsForJob, [readyToInvoice.jobRefId]: {
                ...data,
                content: updatedData
              }}
            });
        }),
        catchError(() => dispatch(new SetNotificationAction('Unable to update ready to invoice', AlertDialogType.ERROR)))
      );
  }
}
