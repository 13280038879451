import {Injectable} from '@angular/core';
import {RateCard} from '@shared/models/rate-card.model';
import {RateRow} from '@quotation/models/rate-row.model';
import {RateSheetType} from '@shared/models/enums/rate-sheet-type.enum';
import {InvoiceCurrency} from '../models/invoice-currency.model';
import {PaymentTerm} from '@shared/models/payment-term.model';
import {PaymentTermType} from '@shared/models/enums/payment-term-type.enum';
import {Store} from '@ngxs/store';
import {Step} from '@shared/models/step.model';
import {Invoice} from '@shared/models/invoice/invoice.model';
import {VehicleContribution} from '@shared/models/invoice/vehicle-contribution.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceExtraService {

  invoicePLSteps: Step[] = [
    {
      stepNo: 1,
      stepName: 'Draft',
      step: 'DRAFT'
    },
    {
      stepNo: 2,
      stepName: 'Sent for Internal Approval',
      step: 'SENT_FOR_INTERNAL_APPROVAL'
    },
    {
      stepNo: 3,
      stepName: 'Internally Approved',
      step: 'INTERNAL_APPROVED'
    },
    {
      stepNo: 4,
      stepName: 'Sent',
      step: 'SENT'
    },
    {
      stepNo: 5,
      stepName: 'Paid',
      step: 'PAID'
    },
  ];

  paymentScheduleSteps: Step[] = [
    {
      stepNo: 1,
      stepName: 'Draft',
      step: 'DRAFT'
    },
    {
      stepNo: 2,
      stepName: 'Sent for Internal Approval',
      step: 'SENT_FOR_INTERNAL_APPROVAL'
    },
    {
      stepNo: 3,
      stepName: 'Internally Approved',
      step: 'INTERNAL_APPROVED'
    },
    {
      stepNo: 4,
      stepName: 'Confirm',
      step: 'CONFIRM'
    },
    {
      stepNo: 5,
      stepName: 'Paid',
      step: 'PAID'
    },
  ];

  constructor(private store$: Store) { }

  generateRateRows(rateCard: RateCard, currency: InvoiceCurrency, vehicleCategory: string): RateRow[][] {
    let rates: RateRow[] = [];
    switch (rateCard?.type) {
      case RateSheetType.FLAG_DOWN:
        rates = this.generateFlagDownRateRows(rateCard, currency, vehicleCategory);
        break;
      case RateSheetType.TEUS:
        rates = this.generateTuesRateRows(rateCard, currency);
        break;
      case RateSheetType.LOAD_BASED:
        rates = this.generateLoadBasedRateRows(rateCard, currency);
        break;
      case RateSheetType.COMMITMENT:
        rates = this.generateCommitmentRateRows(rateCard, currency);
        break;
      default:
        rates = rateCard?.type === null ? this.generateFlagDownRateRows(rateCard, currency, vehicleCategory) : [];
    }
    return this.splitRatesIntoRows(rates);
  }

  getVehicleCategoryName(rateCard: RateCard, vehicleCategory: string){
    const flagDownRate
      = rateCard.flagDownRates[vehicleCategory] != null ? rateCard.flagDownRates[vehicleCategory] : rateCard.flagDownRates.default;
    return flagDownRate.vehicleCategoryName;
  }

  getDemurrageSlabs(rateCard: RateCard, vehicleCategory: string){
    return (rateCard.flagDownRates[vehicleCategory] != null
      ? rateCard.flagDownRates[vehicleCategory] : rateCard.flagDownRates.default).demurrageSlabs;
  }

  generateFlagDownRateRows(rateCard: RateCard, currency: InvoiceCurrency, vehicleCategory: string): RateRow[] {
    const rates: RateRow[] = [];
    const flagDownRate
      = rateCard.flagDownRates[vehicleCategory] != null ? rateCard.flagDownRates[vehicleCategory] : rateCard.flagDownRates.default;
    if (flagDownRate?.flagDownCostActivated) {
      rates['Flag Down Rate'] = flagDownRate?.flagDownCost;
      rates.push({
        name: 'Flag Down Rate',
        value: this.applyCurrency(flagDownRate?.flagDownCost, currency)
      });
    }

    if (flagDownRate?.jobFreeHoursActivated) {
      rates.push({
        name: 'Free Hours per Flag Down',
        value: flagDownRate?.jobFreeHours
      });
    }

    if (flagDownRate?.flagDownFrequencyActivated) {
      rates.push({
        name: 'Flag Down Application (Frequency)',
        value: flagDownRate?.flagDownFrequency + ' hrs'
      });
    }

    if (flagDownRate?.freeKmsPerFlagDownActivated) {
      rates.push({
        name: 'Free KM\'s per Flag Down',
        value: flagDownRate?.freeKmsPerFlagDown
      });
    }

    if (rateCard?.sellingRatePerkm) {
      rates.push({
        name: 'Rate/km',
        value: this.applyCurrency(rateCard?.sellingRatePerkm, currency)
      });
    }

    return rates;
  }

  generateTuesRateRows(rateCard: RateCard, currency: InvoiceCurrency): RateRow[] {
    const rates: RateRow[] = [];
    if (rateCard?.ratePerTeuActivated) {
      rates.push({
        name: 'Rate per TEU',
        value: this.applyCurrency(rateCard?.ratePerTeu, currency)
      });
    }

    if (rateCard?.freeHoursPerTripActivated) {
      rates.push({
        name: 'Free Hours per trip',
        value: rateCard?.freeHoursPerTrip
      });
    }

    if (rateCard?.freeKmsPerTripActivated) {
      rates.push({
        name: 'Free Kilometers per Trip',
        value: rateCard?.freeKmsPerTrip
      });
    }

    if (rateCard?.sellingRatePerExtraKmActivated) {
      rates.push({
        name: 'Rate/km',
        value: this.applyCurrency(rateCard?.sellingRatePerExtraKm, currency)
      });
    }

    return rates;
  }

  generateLoadBasedRateRows(rateCard: RateCard, currency: InvoiceCurrency): RateRow[] {
    const rates: RateRow[] = [];
    if (rateCard?.rateFor20FtActivated) {
      rates.push({
        name: '20 Ft Rate',
        value: this.applyCurrency(rateCard?.rateFor20Ft, currency)
      });
    }

    if (rateCard?.rateFor40FtActivated) {
      rates.push({
        name: '40 Ft Rate',
        value: this.applyCurrency(rateCard?.rateFor40Ft, currency)
      });
    }

    if (rateCard?.rateFor45FtActivated) {
      rates.push({
        name: '45 Ft Rate',
        value: this.applyCurrency(rateCard?.rateFor45Ft, currency)
      });
    }

    if (rateCard?.rateFor40FtReferActivated) {
      rates.push({
        name: '40 Ft Refer Rate',
        value: this.applyCurrency(rateCard?.rateFor40FtRefer, currency)
      });
    }

    if (rateCard?.freeHoursPerTripActivated) {
      rates.push({
        name: 'Free Hours per trip',
        value: rateCard?.freeHoursPerTrip
      });
    }

    if (rateCard?.freeKmsPerTripActivated) {
      rates.push({
        name: 'Free Kilometers per Trip',
        value: rateCard?.freeKmsPerTrip
      });
    }

    if (rateCard?.sellingRatePerExtraKmActivated) {
      rates.push({
        name: 'Rate/km',
        value: this.applyCurrency(rateCard?.sellingRatePerExtraKm, currency)
      });
    }

    return rates;
  }

  generateCommitmentRateRows(rateCard: RateCard, currency: InvoiceCurrency): RateRow[] {
    const rates: RateRow[] = [];
    rateCard.commitmentBlocks.forEach(block => {
      if (block?.isCommitmentExtraKmCharge) {
        rates.push({
          name: 'Extra km cost',
          value: this.applyCurrency(block?.commitmentExtraKmCharge, currency)
        });
      }
    });
    return rates;
  }


    splitRatesIntoRows(rates: RateRow[]) {
    return rates.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 2);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }

  applyCurrency(value: number, currency: InvoiceCurrency): string {
    return currency.currentCurrency + ' ' + (+(value / currency.currencyRate)?.toFixed(2)).toLocaleString();
  }

  generatePaymentTermString(paymentTerm: PaymentTerm, invoiceCurrency: InvoiceCurrency, total: number): string{
    let paymentTermString = 'Pay ';

    if (paymentTerm.advance > 0) {
      if (paymentTerm.paymentType === PaymentTermType.PERCENTAGE){
        paymentTermString = paymentTermString + paymentTerm.advance.toFixed(2) + '%';
      }
      else {
        paymentTermString
          = paymentTermString + (+paymentTerm.advance.toFixed(2)) + ' ' + invoiceCurrency.currentCurrency;
      }
      paymentTermString = paymentTermString + ' in advance';
    }

    const balance = paymentTerm.paymentType === PaymentTermType.PERCENTAGE ? 100 - paymentTerm.advance
      : (total - paymentTerm.advance * invoiceCurrency.currencyRate) / invoiceCurrency.currencyRate;

    if (balance > 0) {
      if (paymentTerm.advance > 0) {
        paymentTermString = paymentTermString + ' and';
      }
      paymentTermString = paymentTermString + ' ' + balance.toFixed(2) + (paymentTerm.paymentType === PaymentTermType.PERCENTAGE
        ? '%' : ' ' + invoiceCurrency.currentCurrency);
    }

    if (balance > 0 && paymentTerm.creditDays > 0) {
      paymentTermString = paymentTermString + ' in ' + paymentTerm.creditDays + ' days';
    }

    if (balance > 0) {
      paymentTermString = paymentTermString + ' upon completion';
    }

    return paymentTermString;
  }

  checkIsCommitment(invoiceOrPs: Invoice, loadId: string) {
    const vehicleContributions: VehicleContribution[] = invoiceOrPs.commitmentDetails.blocks.reduce((vcs, block) => {
      vcs = [...vcs, ...block.vehicleContributions];
      return vcs;
    }, []);
    const index = vehicleContributions.findIndex(vc => vc.loadId === loadId);
    return (index > -1 && !vehicleContributions[index].isCommitment);
  }
}
