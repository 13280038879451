import {gql} from 'graphql-tag';

export const findInvoiceAbleJobs = gql
    `query findInvoiceAbleJobs($orgId: String!, $createdTo: String!, $pageNo: Float!, $pageSize: Float!,
    $searchKey: String!,  $invoiceType: InvoiceType!, $toDate: DateTime!, $fromDate: DateTime!) {
        findInvoiceAbleJobs(orgId: $orgId, createdTo: $createdTo, pageNo: $pageNo, pageSize: $pageSize,
        searchKey: $searchKey, invoiceType: $invoiceType, fromDate: $fromDate, toDate: $toDate ) {
            data {
                jobRefId
                jobTitle
                shipper
                consignee
                shippingLine
                completedLoads
                pendingLoads
                contractRefNo
                customerRefNo
                createdTo
                orgPartnerId
                workFlowId
            }
            totalElements
            pageNo
            pageSize
        }
    }`;

export const countCompletedLoadsByCreatedTo = gql
    `query countCompletedLoadsByCreatedTo($orgId: String!, $createdToIds: [String!]! , $isCompleted: Boolean!,
    $invoiceType: InvoiceType!, $toDate: DateTime!, $fromDate: DateTime!){
        countCompletedLoadsByCreatedTo(
              orgId: $orgId,
              createdToIds: $createdToIds,
              isCompleted: $isCompleted,
              invoiceType: $invoiceType,
              fromDate: $fromDate,
              toDate: $toDate
            ){
            createdTo
            count
        }
    } `;

export const createInvoice = gql
    `mutation createInvoice($createInvoiceInput: CreateInvoiceInput!){
        createInvoice(createInvoiceInput: $createInvoiceInput){
            _id
            type
        }
    }`;

export const updateReadyToInvoiceCostsDiscounts = gql `mutation updateReadyToInvoiceCostsDiscounts($UpdateReadyToInvoiceDiscountCostInput: UpdateReadyToInvoiceDiscountCostInput!) {
    updateReadyToInvoiceCostsDiscounts(updateReadyToInvoiceCostsAndDiscountsInput: $UpdateReadyToInvoiceDiscountCostInput) {
        _id,
        jobRefId,
        contractRefNo,
        jobTitle,
        jpmId,
        orgId,
        rateCard,
        createdTo,
        consignee,
        shippingLine,
        shipper,
        serviceProvider
        loadId,
        loadNumber,
        loadCost,
        jpmId,
        completed,
        invoiced,
        containerType,
        jobTitle,
        rateCardCalculationRequest{
            rateSheet{
                defaultOrgCurrencyCode,
                currencyCode,
                exchangeRate
            }
            trips{
                distance
            }
        },
        paymentTerm{
            advance
            creditDays
            paymentType
        },
        expenses{
            id
            amount
            costDescription
          paymentType
          feeType
        }
        locations
        operationId
        addedDiscount{
            id
            amount
            costDescription
          paymentType
        }
        addedOtherCost{
            id
            amount
            costDescription
          paymentType
        }
        addedAdditionalCost{
            id
            amount
            costDescription
          paymentType
        }
        commissions{
            id
            amount
            costDescription
            paymentType
            feeType
        }
    }
  }`;

export const updateInvoice = gql
    `mutation updateInvoice($updateInvoiceInput: UpdateInvoiceInput!){
        updateInvoice(updateInvoiceInput:$updateInvoiceInput){
            _id
            invoiceRefNo
            jobTitles
            orgId
            internalRefNos
            contractRefNos
            createdAt
            stateUpdatedAt
            preparedBy
            serviceProvider
            createdTo
            customerRefNo
            loads{
                _id
                loadId
                loadNumber
                loadCost
                costs{
                    id
                    description
                    value
                    feeType
                }
                jpmId
                jobRefId
                total
                containerType
                jobTitle
                defaultOrgCurrencyCode
                currencyCode
                exchangeRate
                paymentTerm{
                    advance
                    creditDays
                    paymentType
                }
                rateCard
                locations
                distance
                operationId
                createdTo
                addedDiscount{
                    id
                    description
                    value
                    paymentType
                }
                addedOtherCost{
                    id
                    description
                    value
                    paymentType
                }
                addedAdditionalCost{
                    id
                    description
                    value
                    paymentType
                }
                vehicleCategories
                commissions{
                    id
                    description
                    value
                    paymentType
                    feeType
                }
            }
            additionalCosts{
                id
                description
                value
            }
            loadsCosts{
                id
                description
                value
            }
            progressStep
            approvalState
            overdueState
            tab
            taxes{
                id
                description
                value
                paymentType
            }
            discount{
                id
                description
                value
                paymentType
            }
            otherCosts{
                id
                description
                value
                paymentType
            }
            total
            currency{
                defaultCurrency
                currentCurrency
                currencyRate
            }
            suspendedVat
            invoicePaymentTerm{
                advance
                creditDays
                paymentType
            }
            invoicePaymentTermStr
            loadsExpandState
            defaultOrgCurrencyCode
            currencyCode
            exchangeRate
            type
            commitmentDetails  {
                organizationId
                fromDate
                toDate
                blocks {
                    amount
                    commitmentKilometers
                    isCommitmentExtraKmCharge
                    commitmentExtraKmCharge
                    isAllVehicle
                    vehicles
                    vehicleCategory
                    vehicleCategoryName
                    vehicleContributions{
                        distance
                        vehicleCategory
                        vehicleId
                        vehicleNumber
                        loadId
                        loadNumber
                        isCommitment
                        fuelAllowanceAmount
                    }
                    loadsExtraKMCosts{
                        id
                        description
                        value
                    }
                    affectedDays
                    fuelEfficiency
                    fuelRelatedData{
                        date
                        fuelRate{
                            rateId
                            fuelType
                            currentRate
                            newRate
                            customerContractIds
                            supplierContractIds
                            rateCardIds
                            selectAllCustomer
                            selectAllSupplier
                            selectAllRateCards    
                        }
                        commitmentRate
                        extraKmRate
                        usedKMbetweenRange
                        valueOfFirstPart
                        valueOfSecondPart
                        remainingKm
                        loadContributions
                    }
                    isFuelAllowance
                    fuelAllowanceType
                    fixedFuelAllowanceType
                    fixedFuelAllowanceAmount
                }
                rateCardId
                contractName
            }
        }
    } `;

export const removeLoad = gql
    `mutation removeLoad($id: String!, $invoiceType: InvoiceType!, $invoiceId: String!){
        removeLoad(id: $id,invoiceType: $invoiceType,  invoiceId: $invoiceId){
            _id
            invoiceRefNo
            jobTitles
            orgId
            internalRefNos
            contractRefNos
            createdAt
            preparedBy
            serviceProvider
            createdTo
            customerRefNo
            loads{
                loadId
                loadNumber
                loadCost
                costs{
                    id
                    description
                    value
                    feeType
                }
                jpmId
                jobRefId
                total
                containerType
                jobTitle
                defaultOrgCurrencyCode
                currencyCode
                exchangeRate
                paymentTerm{
                    advance
                    creditDays
                    paymentType
                }
                rateCard
                locations
                operationId
                addedDiscount{
                    id
                    description
                    value
                    paymentType
                }
                addedOtherCost{
                    id
                    description
                    value
                    paymentType
                }
                addedAdditionalCost{
                    id
                    description
                    value
                    paymentType
                }
                commissions{
                    id
                    description
                    value
                    paymentType
                    feeType
                }
            }
            additionalCosts{
                id
                description
                value
            }
            loadsCosts{
                id
                description
                value
            }
            progressStep
            approvalState
            overdueState
            tab
            taxes{
                id
                description
                value
                paymentType
            }
            discount{
                id
                description
                value
                paymentType
            }
            otherCosts{
                id
                description
                value
                paymentType
            }
            total
            currency{
                defaultCurrency
                currentCurrency
                currencyRate
            }
            suspendedVat
            invoicePaymentTerm{
                advance
                creditDays
                paymentType
            }
            invoicePaymentTermStr
            loadsExpandState
            defaultOrgCurrencyCode
            currencyCode
            exchangeRate
        }
    }`;

export const removeAllLoads = gql
    `mutation removeAllLoads($invoiceId: String!, $invoiceType: InvoiceType!){
        removeAllLoads(invoiceId: $invoiceId, invoiceType: $invoiceType){
            _id
            invoiceRefNo
            jobTitles
            orgId
            internalRefNos
            contractRefNos
            createdAt
            preparedBy
            serviceProvider
            createdTo
            customerRefNo
            loads{
                loadId
                loadNumber
                loadCost
                costs{
                    id
                    description
                    value
                    feeType
                }
                jpmId
                jobRefId
                total
                containerType
                jobTitle
                defaultOrgCurrencyCode
                currencyCode
                exchangeRate
                paymentTerm{
                    advance
                    creditDays
                    paymentType
                }
                rateCard
                locations
                operationId
                addedDiscount{
                    id
                    description
                    value
                    paymentType
                }
                addedOtherCost{
                    id
                    description
                    value
                    paymentType
                }
                addedAdditionalCost{
                    id
                    description
                    value
                    paymentType
                }
                commissions{
                    id
                    description
                    value
                    paymentType
                    feeType
                }
            }
            additionalCosts{
                id
                description
                value
            }
            loadsCosts{
                id
                description
                value
            }
            progressStep
            approvalState
            overdueState
            tab
            taxes{
                id
                description
                value
                paymentType
            }
            discount{
                id
                description
                value
                paymentType
            }
            otherCosts{
                id
                description
                value
                paymentType
            }
            total
            currency{
                defaultCurrency
                currentCurrency
                currencyRate
            }
            suspendedVat
            invoicePaymentTerm{
                advance
                creditDays
                paymentType
            }
            invoicePaymentTermStr
            loadsExpandState
            defaultOrgCurrencyCode
            currencyCode
            exchangeRate
        }
    }`;

export const findCustomers = gql
    `query findCustomers($toDate: DateTime!, $fromDate: DateTime!, $orgId: String!) {
        findCustomers(fromDate: $fromDate, toDate: $toDate, orgId: $orgId)
    }`;

export const findSuppliers = gql
    `query findSuppliers($toDate: DateTime!, $fromDate: DateTime!, $orgId: String!) {
        findSuppliers(fromDate: $fromDate, toDate: $toDate, orgId: $orgId)
    }`;

export const findLoadsByJobRefIdAndIsCompleted = gql
    `query findLoadsByJobRefIdAndIsCompleted($orgId: String!, $createdTo: String!, $jobRefId: String!, $isCompleted: Boolean! , $pageNo: Float!, $pageSize: Float!, $invoiceType: InvoiceType!){
        findLoadsByJobRefIdAndIsCompleted(orgId: $orgId, createdTo: $createdTo, jobRefId: $jobRefId, isCompleted: $isCompleted, pageNo: $pageNo, pageSize: $pageSize, invoiceType: $invoiceType){
            totalElements
            pageNo
            pageSize
            data {
                _id,
                jobRefId,
                contractRefNo,
                jobTitle,
                jpmId,
                orgId,
                rateCard,
                createdTo,
                consignee,
                shippingLine,
                shipper,
                serviceProvider
                loadId,
                loadNumber,
                loadCost,
                jpmId,
                completed,
                invoiced,
                containerType,
                jobTitle,
                rateCardCalculationRequest{
                    rateSheet{
                        defaultOrgCurrencyCode,
                        currencyCode,
                        exchangeRate
                    }
                    trips{
                        distance
                    }
                },
                paymentTerm{
                    advance
                    creditDays
                    paymentType
                },
                expenses{
                    id
                    amount
                    costDescription
                    paymentType
                    feeType
                }
                locations
                operationId
                addedDiscount{
                    id
                    amount
                    costDescription
                    paymentType
                }
                addedOtherCost{
                    id
                    amount
                    costDescription
                    paymentType
                }
                addedAdditionalCost{
                    id
                    amount
                    costDescription
                    paymentType
                }
                vehicleCategories
                commissions{
                    id
                    amount
                    costDescription
                    paymentType
                    feeType
                }
            }
        }
    }`;

export const findLoadsByJobRefId = gql
    `query findLoadsByJobRefId($orgId: String!, $jobRefId: String!, $pageNo: Float!, $pageSize: Float!){
        findLoadsByJobRefId(orgId: $orgId, jobRefId: $jobRefId, pageNo: $pageNo, pageSize: $pageSize){
            totalElements
            pageNo
            pageSize
            data {
                jobRefId,
                jobTitle,
                jpmId,
                orgId,
                rateCard,
                createdTo,
                consignee,
                shippingLine,
                shipper,
                serviceProvider
                loadId,
                loadCost,
                loadNumber,
                jpmId,
                completed,
                invoiced
            }
        }
    }`;

export const findAllCompletedLoadsByOrg = gql
    `query findAllCompletedLoadsByOrg($orgId: String!,$createdTo: String!, $isCompleted: Boolean!, $searchKey: String!, $invoiceType: InvoiceType!, $fromDate: DateTime!, $toDate: DateTime!){
        findAllCompletedLoadsByOrg(orgId: $orgId, createdTo: $createdTo, isCompleted: $isCompleted, searchKey: $searchKey,  invoiceType: $invoiceType,fromDate: $fromDate, toDate: $toDate){
            _id,
            jobRefId,
            contractRefNo,
            jobTitle,
            jpmId,
            orgId,
            rateCard,
            createdTo,
            consignee,
            shippingLine,
            shipper,
            serviceProvider
            loadId,
            loadNumber,
            jpmId,
            completed,
            invoiced,
            loadCost,
            containerType,
            jobTitle,
            rateCardCalculationRequest{
                rateSheet{
                    defaultOrgCurrencyCode,
                    currencyCode,
                    exchangeRate
                }
                trips{
                    distance
                }
            },
            paymentTerm{
                advance,
                creditDays,
                paymentType
            },
            expenses{
                id,
                amount,
                costDescription
                paymentType
                feeType
            }
            locations
            operationId
            addedDiscount{
                id
                amount
                costDescription
                paymentType
            }
            addedOtherCost{
                id
                amount
                costDescription
                paymentType
            }
            addedAdditionalCost{
                id
                amount
                costDescription
                paymentType
            }
            commissions{
                id
                amount
                costDescription
                paymentType
                feeType
                }
        }
    }`;

export const findAllCompletedLoadsByJob = gql
    `query findAllCompletedLoadsByJob($orgId: String!, $jobRefId: String!, $isCompleted: Boolean! ){
        findAllCompletedLoadsByJob(orgId: $orgId, jobRefId: $jobRefId, isCompleted: $isCompleted){
            _id,
            jobRefId,
            contractRefNo,
            jobTitle,
            jpmId,
            orgId,
            rateCard,
            createdTo,
            consignee,
            shippingLine,
            shipper,
            serviceProvider
            loadId,
            loadNumber,
            jpmId,
            completed,
            invoiced,
            loadCost,
            containerType,
            jobTitle,
            rateCardCalculationRequest{
                rateSheet{
                    defaultOrgCurrencyCode,
                    currencyCode,
                    exchangeRate
                }
                trips{
                    distance
                }
            },
            paymentTerm{
                advance,
                creditDays,
                paymentType
            },
            expenses{
                id,
                amount,
                costDescription,
                paymentType,
                feeType
            }
            locations
            operationId
            addedDiscount{
                id
                amount
                costDescription
                paymentType
            }
            addedOtherCost{
                id
                amount
                costDescription
                paymentType
            }
            addedAdditionalCost{
                id
                amount
                costDescription
                paymentType
            }
            commissions{
              id
              amount
              costDescription
              paymentType
              feeType
            }
        }
    }`;

export const findInvoicesByInvoiceTypeAndApprovalType = gql
    `query findInvoicesByInvoiceTypeAndApprovalType($orgId: String!, $tabType: TabType!, $toDate: DateTime!, $fromDate: DateTime!,
        $approvalType: ApprovalStateType!, $pageNo: Float!, $pageSize: Float!, $invoiceType: InvoiceType!, $searchKey: String!){
        findInvoicesByInvoiceTypeAndApprovalType(
            orgId: $orgId,
            tabType: $tabType,
            approvalType: $approvalType,
            pageNo: $pageNo,
            pageSize: $pageSize,
            invoiceType: $invoiceType,
            searchKey: $searchKey,
            fromDate: $fromDate,
            toDate: $toDate,
        ){
            totalElements,
            pageNo,
            pageSize,
            data{
                _id
                invoiceRefNo
                createdAt
                createdTo
                total
                approvalState
                tab
                currencyCode
                type
                stateUpdatedAt
            }
        }
    }`;

export const findCommitmentInvoicesForOrganization = gql
    `query findCommitmentInvoicesForOrganization($searchKey: String!, $pageSize: Float!, $pageNo: Float!, $invoiceType: InvoiceType!,
    $organizationId: String!, $orgId: String!, $toDate: DateTime!, $fromDate: DateTime!){
        findCommitmentInvoicesForOrganization(
              searchKey: $searchKey,
              pageSize:  $pageSize,
              pageNo: $pageNo,
              invoiceType: $invoiceType,
              organizationId:$organizationId,
              orgId: $orgId,
              fromDate: $fromDate,
              toDate: $toDate,
            ){
            totalElements
            pageNo
            pageSize
            data{
                _id
                invoiceRefNo
                createdAt
                commitmentDetails{
                    organizationId
                    fromDate
                    toDate
                    contractName
                }
            }
        }
    }`;

export const findByInvoiceTypeAndOverdueType = gql
    `query findByInvoiceTypeAndOverdueType($orgId: String!, $tabType: TabType!, $toDate: DateTime!, $fromDate: DateTime!,
    $overdueState: OverdueStateType!, $pageNo: Float!, $pageSize: Float!, $invoiceType: InvoiceType!, , $searchKey: String!){
        findByInvoiceTypeAndOverdueType(
            orgId: $orgId,
            tabType: $tabType,
            overdueState: $overdueState,
            pageNo: $pageNo,
            pageSize: $pageSize,
            invoiceType: $invoiceType,
            searchKey: $searchKey,
            fromDate: $fromDate,
            toDate: $toDate,
        ){
            totalElements,
            pageNo,
            pageSize,
            data{
                _id
                invoiceRefNo
                createdAt
                createdTo
                total
                approvalState
                tab
                type
                stateUpdatedAt
            }
        }
    }`;

export const findInvoiceById = gql
    `query findInvoiceById($id: String!){
        findInvoiceById(id: $id){
            _id
            invoiceRefNo
            jobTitles
            orgId
            internalRefNos
            contractRefNos
            createdAt
            stateUpdatedAt
            preparedBy
            serviceProvider
            createdTo
            customerRefNo
            loads{
                _id
                loadId
                loadNumber
                loadCost
                costs{
                    id
                    description
                    value
                    feeType
                }
                jpmId
                jobRefId
                total
                containerType
                jobTitle
                defaultOrgCurrencyCode
                currencyCode
                exchangeRate
                paymentTerm{
                    advance
                    creditDays
                    paymentType
                }
                rateCard
                locations
                distance
                operationId
                createdTo
                addedDiscount{
                    id
                    description
                    value
                    paymentType
                }
                addedOtherCost{
                    id
                    description
                    value
                    paymentType
                }
                addedAdditionalCost{
                    id
                    description
                    value
                    paymentType
                }
                vehicleCategories
                commissions{
                    id
                    description
                    value
                    paymentType
                    feeType
                }
            }
            additionalCosts{
                id
                description
                value
            }
            loadsCosts{
                id
                description
                value
            }
            progressStep
            approvalState
            overdueState
            tab
            taxes{
                id
                description
                value
                paymentType
            }
            discount{
                id
                description
                value
                paymentType
            }
            otherCosts{
                id
                description
                value
                paymentType
            }
            total
            currency{
                defaultCurrency
                currentCurrency
                currencyRate
            }
            suspendedVat
            invoicePaymentTerm{
                advance
                creditDays
                paymentType
            }
            invoicePaymentTermStr
            loadsExpandState
            defaultOrgCurrencyCode
            currencyCode
            exchangeRate
            type
            commitmentDetails  {
                organizationId
                fromDate
                toDate
                blocks {
                    amount
                    commitmentKilometers
                    isCommitmentExtraKmCharge
                    commitmentExtraKmCharge
                    isAllVehicle
                    vehicles
                    vehicleCategory
                    vehicleCategoryName
                    vehicleContributions{
                        distance
                        vehicleCategory
                        vehicleId
                        vehicleNumber
                        loadId
                        loadNumber
                        isCommitment
                        fuelAllowanceAmount
                    }
                    loadsExtraKMCosts{
                        id
                        description
                        value
                    }
                    affectedDays
                    fuelEfficiency
                    isFuelAllowance
                    fuelAllowanceType
                    fixedFuelAllowanceType
                    fuelRelatedData{
                        date
                        fuelRate{
                            rateId
                            fuelType
                            currentRate
                            newRate
                            customerContractIds
                            supplierContractIds
                            rateCardIds
                            selectAllCustomer
                            selectAllSupplier
                            selectAllRateCards    
                        }
                        commitmentRate
                        extraKmRate
                        usedKMbetweenRange
                        valueOfFirstPart
                        valueOfSecondPart
                        remainingKm
                        loadContributions
                    }
                    isFuelAllowance
                    fuelAllowanceType
                    fixedFuelAllowanceType
                    fixedFuelAllowanceAmount
                }
                rateCardId
                contractName
            }
        }
    }`;

export const fidByCountry = gql
    `query fidByCountry($countryCode: String!){
        findByCountry(countryCode:$countryCode){
            _id
            name
            percentage
            countryCode
        }
    }`;

export const changeRateCardForTrip = gql
  `mutation changeRateCardForTrip($jpmId: String!, $createdTo: String!, $rateCardId: String!){
  changeRateCardForTrip(jpmId:$jpmId, createdTo: $createdTo, rateCardId: $rateCardId )
}`;

export const uploadPdfAndGetId = gql
    `mutation uploadPdfAndGetId($orgId: String!, $invoiceId: String!){
        uploadPdfAndGetId(orgId: $orgId, invoiceId: $invoiceId)
    }`;

export const findAllCurrency = gql
    `query findAllCurrency{
        findAllCurrency{
            _id
            currency
            code
            countryCode
            shortCode
        }
    }`;
export const updateFuelRateInInvoiceQuery = gql
    `mutation updateFuelRateInInvoice($updateFuelRateInInvoice: FuelRateUpdateInvoiceInput!){
         updateFuelRateInInvoice(updateFuelRateInInvoice: $updateFuelRateInInvoice)
    }`;